<template>
  <tr :class="{ 'is-busy': isDeleting }">
    <td @click="viewClient(client)" class="align-middle" role="button">
      <UserProfile :user="client" class="flex-grow-1"></UserProfile>
    </td>
    <td @click="viewClient(client)" class="align-middle" role="button">
      <UserConsistency :user="client" class="small"></UserConsistency>
    </td>
    <td @click="viewClient(client)" class="align-middle" role="button">
      <UserProgramDue :dueDate="client.dueDate"></UserProgramDue>
    </td>
    <td class="align-middle text-end">
      <div v-if="isDeleting" class="btn btn-circle">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </div>

      <BootstrapDropdown v-else>
        <li
          @click.stop="viewClient(client, 'calendar')"
          class="dropdown-item small"
          role="button"
        >
          <fa-icon :icon="['fas', 'calendar-week']" class="me-1"></fa-icon>
          Calendar
        </li>
        <li
          @click.stop="viewClient(client, 'nutrition')"
          class="dropdown-item small"
          role="button"
        >
          <fa-icon :icon="['fas', 'utensils']" class="me-1"></fa-icon>
          Nutrition
        </li>
        <li
          @click.stop="viewClient(client, 'metric')"
          class="dropdown-item small"
          role="button"
        >
          <fa-icon :icon="['fas', 'chart-line']" class="me-1"></fa-icon>
          Metric
        </li>
        <li
          @click.stop="viewClient(client, 'profile')"
          class="dropdown-item small"
          role="button"
        >
          <fa-icon :icon="['fas', 'user-circle']" class="me-1"></fa-icon>
          Profile
        </li>
        <li><hr class="dropdown-divider"></li>
        <li
          @click="showMessageModal = true"
          class="dropdown-item small"
          role="button"
        >
          <fa-icon :icon="['fas', 'envelope']" class="me-1"></fa-icon>
          Send message
        </li>
        <li><hr class="dropdown-divider"></li>
        <li
          @click="showConfirmModal = true"
          class="dropdown-item small text-danger"
          role="button"
        >
          <fa-icon :icon="['fas', 'trash-alt']" class="me-1"></fa-icon>
          Remove
        </li>
      </BootstrapDropdown>

      <portal to="modal">
        <transition name="modal">
          <ConfirmModal
            @confirm="removeClient"
            @close="showConfirmModal = false"
            v-model="showConfirmModal"
            v-if="showConfirmModal"
            :forceConfirmation="true"
            :yesLabel="'Remove client'"
            :message="`
              You are about to remove ${client.firstName} as your client.
              This action cannot be undone.
              Proceed with caution.
            `"
          ></ConfirmModal>
        </transition>
      </portal>

      <portal to="modal">
        <transition name="modal">
          <MessageModal
            @close="showMessageModal = false"
            v-model="showMessageModal"
            v-if="showMessageModal"
            :user="client"
          ></MessageModal>
        </transition>
      </portal>
    </td>
  </tr>
</template>

<script>
export default {
  components: {
    UserProfile: () => import('@/components/UserProfile'),
    UserConsistency: () => import('@/components/UserConsistency'),
    BootstrapDropdown: () => import('@/components/bootstrap/BootstrapDropdown'),
    ConfirmModal: () => import('@/components/modal/ConfirmModal'),
    UserProgramDue: () => import('@/components/UserProgramDue'),
    MessageModal: () => import('@/components/modal/MessageModal'),
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    viewClient(client, tab = 'calendar') {
      this.$router.push(`/client/${client.userId}/#${tab}`);
    },
    async removeClient() {
      this.showConfirmModal = false;
      this.isDeleting = true;
      try {
        const { userId, firstName } = this.client;
        await this.$store.dispatch('client/deleteClient', userId);
        this.$store.dispatch('addSystemMessage', `${firstName} has been removed from your client list.`, { root: true });
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isDeleting = false;
      }
    },
  },
  data() {
    return {
      showConfirmModal: false,
      showMessageModal: false,
      isDeleting: false,
    };
  },
};
</script>
